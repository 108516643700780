// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { ModalCanvas, ModalContainer } from '@storylinerlabs/design';

// import styles
import styles from './VideoModal.module.css';

// VideoModal component
const VideoModal: React.FunctionComponent<{
  closeModal: () => void;
  isActive: boolean;
  videoTitle: string;
  videoUrl: string;
}> = ({ closeModal, isActive, videoTitle, videoUrl }) => (
  <ModalContainer closeModal={closeModal} isActive={isActive}>
    <ModalCanvas isActive={isActive} closeModal={closeModal}>
      <div className={styles['container']}>
        {isActive && (
          <div className={styles['iframe-container']}>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="100%"
              src={videoUrl}
              title={videoTitle}
              width="100%"
            />
          </div>
        )}
      </div>
    </ModalCanvas>
  </ModalContainer>
);

VideoModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  videoTitle: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default VideoModal;
